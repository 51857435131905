import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import Select from "react-select";
import backgroundImg from "../../assets/background-demo.png";
import countries from "../../countries.json";
import CameraGuideIcon from "../../icons/CameraGuideIcon";
import CheckIcon from "../../icons/CheckIcon";
import XIcon from "../../icons/XIcon";
import { formatDateTime } from "../../lib/utils";
import { useApiService } from "../../services/api.service";
import { kycService } from "../../services/kyc.service";
import { DataTable } from "../DataTable";
import { Card, CardContent } from "../ui/card";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle
} from "../ui/dialog";
import { Label } from "../ui/label";

interface IFormInput {
    id?: number;
    userId?: number;
    language?: string | null;
    country: string;
    firstName: string;
    lastName: string;
    nationality: string;
    gender: string;
    facePath?: string;
    dateOfBirth: Date;
    documentType: string;
    documentFrontPath: string;
    documentBackPath: string;
    extractedInformation: string;
}

interface DefaultResponse {
    flag: boolean;
    data: any;
    message: string;
}

export const KYCVerification = () => {
    const { post } = useApiService();
    const video = useRef<HTMLVideoElement>(null);
    const [cameraStarted, setCameraStarted] = useState<boolean>(false);
    const [open, setOpen] = useState(false);
    const [openView, setOpenView] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const [isFaceScanning, setIsFaceScanning] = useState(false);
    const [isFaceDetected, setIsFaceDetected] = useState<boolean>(true);
    const [countdown, setCountdown] = useState(3);
    const [waitingForScan, setWaitingForScan] = useState(false);
    const [faceMatched, setFaceMatched] = useState(false);
    const [scanPhase, setScanPhase] = useState(1);
    const [isExtracting, setIsExtracting] = useState(false);
    const [isMatching, setIsMatching] = useState(true);
    const [extractedData, setExtractedData] = useState(null);
    const [extractSuccessful, setExtractSuccessful] = useState(false);
    const [currentlyViewing, setCurrentlyViewing] = useState(null);
    const [countryCode, setCountryCode] = useState(null);
    const [isExpired, setIsExpired] = useState(false);
    const [isFrontCamera, setIsFrontCamera] = useState(true);
    const form = useRef<HTMLFormElement>();

    //form
    const { register, handleSubmit, reset, watch, setValue } = useForm<IFormInput>();
    const [fieldErrors, setFieldErrors] = useState<{ [key: string]: string }>({}); // To track validation errors
    const [documentType, setDocumentType] = useState<string>();
    const language = watch("language");
    const country = watch("country");
    const firstName = watch("firstName");
    const lastName = watch("lastName");
    const nationality = watch("nationality");
    const gender = watch("gender");
    const dateOfBirth = watch("dateOfBirth");
    const docType = documentType;
    const [documentFrontPath, setDocumentFrontPath] = useState<string>();
    const [documentBackPath, setDocumentBackPath] = useState<string>();
    const [fileFront, setFileFront] = useState<File | null>(null);
    const [fileBack, setFileBack] = useState<File | null>(null);
    const fileInputRef = useRef(null);

    //stepper
    const NUMBER_OF_STEPS = 9;
    const [currentStep, setCurrentStep] = useState(0);

    //kyc data
    const [kycData, setKycData] = useState<IFormInput[]>([]);
    const columns = [
        {
            header: "First Name",
            accessorKey: "firstName",
        },
        {
            header: "Last Name",
            accessorKey: "lastName",
        },
        {
            header: "Document Type",
            accessorKey: "documentType",
        },
        {
            header: "Date",
            accessorKey: "insertDateTime",
        },
        // {
        //     header: "Action",
        //     accessorKey: "id",
        //     cell: (e) => {
        //         return <button type="button"
        //             onClick={() => viewKyc(e.getValue())}
        //             className="btn greenBg cursor-pointer">
        //             View
        //         </button>
        //     }
        // }
    ];
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        //test remove after
        // debugger
        // if (currentStep == 0) {
        //     setDocumentType("Identity Card");
        //     goToPreviousStep(5);
        //     //getKyc();
        //     //setExtractSuccessful(true);
        // }
        //uncomment
        checkWhatToDo();

    }, [currentStep]);

    useEffect(() => {
        fetchKycHistory();
    }, [])

    const viewKyc = (id) => {
        setOpenView(true);
        setCurrentlyViewing(id);
    }

    const KycModal = () => {
        let data = kycData.find(x => x.id == currentlyViewing);

        if (data) {
            return (
                <Dialog open={openView} onOpenChange={setOpenView}>
                    <DialogContent className="sm:max-w-[625px]" style={{ height: "90%", display: "inline" }}>
                        <p className="kycTitle green align-self-baseline" style={{ fontWeight: "700" }}>Extracted Data</p>
                        <p className="kycDescription green mb-4 mt-2">Below is the information extracted from your document. Please make sure everything looks correct.</p>
                        <div className="row d-flex" style={{ width: "100%", minHeight: "300px" }}>
                            <div className="col-4 pl-0 pr-5" style={{ borderRight: "1px solid rgb(121 121 121 / 10%)" }}>
                                <div id="userPhoto" className="pb-3 mt-1 d-flex align-items-center flex-column" style={{ borderBottom: "1px dotted rgb(121 121 121 / 21%)" }}>
                                    <strong>Face Scanned</strong>
                                    <img src={window.URL.createObjectURL(dataURItoBlob(sessionStorage.getItem("faceFrameBlob")))} style={{ width: "100px", height: "100px", marginTop: "10px", border: "2px solid #068a82" }} />
                                </div>
                                <div id="idPhoto">
                                    <div id="frontIdPhoto" className="pb-3 mt-2 d-flex align-items-center flex-column" style={{ borderBottom: "1px dotted rgb(121 121 121 / 21%)" }}>
                                        <strong>Front ID</strong>
                                        <img src={window.URL.createObjectURL(dataURItoBlob(data.documentFrontPath))}
                                            style={{ width: "100px", height: "100px", marginTop: "10px", border: "2px solid #068a82" }}
                                        />
                                    </div>

                                    {data.documentType == "Identity Card" &&
                                        <div id="backIdPhoto" className="mt-2 d-flex align-items-center flex-column">
                                            <strong>Back ID</strong>
                                            <img src={window.URL.createObjectURL(dataURItoBlob(data.documentBackPath))}
                                                style={{ width: "100px", height: "100px", marginTop: "10px", border: "2px solid #068a82" }}
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="col-8 pr-0 pl-5">
                                <div id="dataExtracted" style={{ width: "100%", height: "100%" }}>
                                    <ul>
                                        {data && Object.keys(data).map(key => {
                                            if (key == "facePath" || key == "documentFrontPath" || key == "documentBackPath" || key == "insertDateTime" || key == "userId" || key == "id") {
                                                return;
                                            }
                                            if (key === "extractedInformation") {
                                                Object.keys(JSON.parse(data[key])).map(innerKey => {
                                                    return (
                                                        <li key={innerKey} style={{ borderBottom: "1px dotted rgb(121 121 121 / 21%)" }}>
                                                            <strong style={{ textTransform: "capitalize" }}>{formatKey(innerKey)}: </strong>
                                                            <span className="green">{data[key]}</span>
                                                        </li>
                                                    );
                                                })
                                            }
                                            if (key == "expiry_date" || key == "dob") {
                                                return (
                                                    <li key={key} style={{ borderBottom: "1px dotted rgb(121 121 121 / 21%)" }}>
                                                        <strong style={{ textTransform: "capitalize" }}>{formatKey(key)}: </strong>
                                                        <span className="green">{new Date(data[key]).toLocaleString().split(",")[0]}</span>
                                                    </li>
                                                );
                                            }
                                            if (data[key] === "id_card") {
                                                return (
                                                    <li key={key} style={{ borderBottom: "1px dotted rgb(121 121 121 / 21%)" }}>
                                                        <strong style={{ textTransform: "capitalize" }}>{formatKey(key)}: </strong>
                                                        <span className="green">Identity Card</span>
                                                    </li>
                                                );
                                            }
                                            else if (data[key] === "driving_license") {
                                                return (
                                                    <li key={key} style={{ borderBottom: "1px dotted rgb(121 121 121 / 21%)" }}>
                                                        <strong style={{ textTransform: "capitalize" }}>{formatKey(key)}: </strong>
                                                        <span className="green">Driver's License</span>
                                                    </li>
                                                );
                                            }
                                            else if (data[key] === "passport") {
                                                return (
                                                    <li key={key} style={{ borderBottom: "1px dotted rgb(121 121 121 / 21%)" }}>
                                                        <strong style={{ textTransform: "capitalize" }}>{formatKey(key)}: </strong>
                                                        <span className="green">Passport</span>
                                                    </li>
                                                );
                                            }
                                            else if (data[key] === "M") {
                                                return (
                                                    <li key={key} style={{ borderBottom: "1px dotted rgb(121 121 121 / 21%)" }}>
                                                        <strong style={{ textTransform: "capitalize" }}>{formatKey(key)}: </strong>
                                                        <span className="green">Male</span>
                                                    </li>
                                                );
                                            }
                                            else if (data[key] === "F") {
                                                return (
                                                    <li key={key} style={{ borderBottom: "1px dotted rgb(121 121 121 / 21%)" }}>
                                                        <strong style={{ textTransform: "capitalize" }}>{formatKey(key)}: </strong>
                                                        <span className="green">Female</span>
                                                    </li>
                                                );
                                            }
                                            else {
                                                if (key != "mrz_validator") {
                                                    return (
                                                        <li key={key} style={{ borderBottom: "1px dotted rgb(121 121 121 / 21%)" }}>
                                                            <strong style={{ textTransform: "capitalize" }}>{formatKey(key)}: </strong>
                                                            <span className="green">{data[key]}</span>
                                                        </li>
                                                    );
                                                }
                                            }
                                        })}

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
            )
        }
    }

    const fetchKycHistory = async () => {
        try {
            const clientId = JSON.parse(localStorage.getItem("user")).clientId;
            const res: DefaultResponse = await kycService.getKycHistory(clientId);

            res.data.forEach((element) => {
                element.insertDateTime = formatDateTime(element.insertDateTime + "Z");
            });

            if (res.flag) {
                setKycData(res.data);
            }
        } catch (err) {
            console.log("Error fetching kyc history:", err);
        } finally {
            setIsLoading(false);
        }
    };

    const goToNextStep = async () => {
        const hasErrors = checkForErrors(currentStep);
        if (!hasErrors) {
            await stopCamera();
            setCurrentStep(prev => prev === NUMBER_OF_STEPS - 1 ? prev : prev + 1);
        }
    }

    async function goToPreviousStep(customStep?: number) {
        const hasErrors = checkForErrors(currentStep);

        if (currentStep == 2) {
            resetFaceScan();
        }
        if (currentStep == 5) {
            await stopCamera();
        }

        if (!hasErrors) {
            if (customStep) {
                setCurrentStep(customStep);
            }
            else {
                setCurrentStep(prev => prev <= 0 ? prev : prev - 1);
            }
        }
    }

    const resetFaceScan = () => {
        setScanPhase(1);
        setCountdown(3);
        setIsFaceDetected(true);
    }

    const checkWhatToDo = async () => {
        if (currentStep == 4) {
            setIsExtracting(false);
            setDocumentFrontPath(null);
            setDocumentBackPath(null);
            setFileFront(null);
            setFileBack(null);
        }
        if (currentStep == 5) {
            await startCamera();
        }
        if (currentStep == 6 && documentType == "Identity Card") {
            await startCamera();
        }
        else if (currentStep == 6 && documentType != "Identity Card") {
            goToNextStep();
        }
        if (currentStep == 7) {
            await stopCamera();
            setIsExtracting(true);
        }
        if (currentStep == 8) {
            save();
        }
    }

    const selectIdPiece = (e) => {
        setDocumentType(e.currentTarget.innerText.trim());
    }

    const startCamera = async (): Promise<void> => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({
                video: currentStep == 2 ? {
                    width: 1920,
                    height: 1080
                } : {
                    width: 4096,
                    height: 2160
                }
            });

            if (video) {
                video.current.srcObject = stream;
                video.current.play();
                setCameraStarted(true);
            }
        } catch (error) {
            console.error("Error starting camera: ", error);
        }
    };

    const stopCamera = async () => {
        return new Promise<void>((resolve) => {

            if (video.current && video.current.srcObject instanceof MediaStream) {
                const stream = video.current.srcObject;
                const tracks = stream.getTracks();
                tracks.forEach(track => track.stop());
                video.current.srcObject = null;
                setTimeout(resolve, 100);
            } else {
                resolve();
            }
        });
    };

    const checkForErrors = (step) => {
        const errors: { [key: string]: string } = {};

        if (step == 0 && (!language || language.trim() === "")) {
            errors.language = "Language is required.";
            setFieldErrors(errors);
            return true;
        }

        if (step == 1 && (!firstName || firstName.trim() === "")) {
            errors.firstName = "First Name is required.";
            setFieldErrors(errors);
            return true;
        }

        if (step == 1 && (!lastName || lastName.trim() === "")) {
            errors.lastName = "Last Name is required.";
            setFieldErrors(errors);
            return true;
        }

        if (step == 1 && (!nationality || nationality.trim() === "")) {
            errors.nationality = "Nationality is required.";
            setFieldErrors(errors);
            return true;
        }

        if (step == 1 && (!gender || gender.trim() === "")) {
            errors.gender = "Gender is required.";
            setFieldErrors(errors);
            return true;
        }

        if (step == 1 && !dateOfBirth) {
            errors.dateOfBirth = "Date of Birth is required.";
            setFieldErrors(errors);
            return true;
        }

        if (step == 3 && (!country || country.trim() === "")) {
            errors.country = "Country is required.";
            setFieldErrors(errors);
            return true;
        }

        if (step == 4 && (!documentType || documentType.trim() === "")) {
            errors.documentType = "Document Type is required.";
            setFieldErrors(errors);
            return true;
        }

        // if (Object.keys(errors).length > 0) {
        //     setFieldErrors(errors);
        //     return true; // Do not proceed if there are validation errors
        // }

        setFieldErrors({}); // Clear errors if validation passes
        return false;
    }

    function dataURItoBlob(dataURI) {
        const byteString = atob(dataURI.split(',')[1])
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
        const ab = new ArrayBuffer(byteString.length)
        const ia = new Uint8Array(ab)

        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i)
        }

        return new Blob([ab], { type: mimeString })
    }

    const getKyc = async () => {
        const reqId = localStorage.getItem("reqId");

        fetch(
            `https://api.voltox.ai/get-kyc?request_id=${reqId}`,
            {
                method: "GET",
            }
        )
            .then((response) => response.json())
            .then((data) => {
                if (data) {
                    //KYC DATA EXTRACTED
                    setExtractSuccessful(true);
                    setIsExtracting(false);
                    setExtractedData(data);

                    //match data
                    setIsMatching(true);

                    let tempDocType = "";

                    if (data.document_type == "id_card") {
                        tempDocType = "Identity Card"
                    }
                    else if (data.document_type == "passport") {
                        tempDocType = "Passport"
                    }
                    else if (data.document_type == "driving_license") {
                        tempDocType = "Driver's License";
                    }

                    if (tempDocType != docType) {
                        console.log("Types dont match!");
                        setExtractSuccessful(false);
                        setIsExtracting(false);
                        setIsMatching(false);
                        return;
                    }

                    if (new Date(data.expiry_date) > new Date()) {
                        setIsExpired(true);
                    }

                    if (fuzzyMatch(data.first_name.toLowerCase(), firstName.toLowerCase()) && fuzzyMatch(data.last_name.toLowerCase(), lastName.toLowerCase())
                        && (data.country_name.toLowerCase() == country.toLowerCase() || data.country_code == countryCode) && (tempDocType != "Driver's License" ? data.nationality.toLowerCase() == nationality.toLowerCase() : true)
                        && (tempDocType != "Driver's License" ? data.gender.toLowerCase() == gender.toLowerCase() : true) && +new Date(data.dob) == +new Date(dateOfBirth)) {
                        //data matches
                        console.log("All Matched")
                    }
                    else {
                        //data does not match
                        setExtractSuccessful(false);
                        setIsExtracting(false);
                        setIsMatching(false);
                        console.log("Some data did not match...")
                        return;
                    }
                }
                else {
                    setExtractSuccessful(false);
                    setIsExtracting(false);
                }

                setIsMatching(false);
            })
            .catch((error) => {
                setExtractSuccessful(false);
                setIsMatching(false);
                setIsExtracting(false);
                console.log(error);
            })
    }

    const checkFaceMatch = async (blob: Blob) => {
        //face matched?
        const faceFormData = new FormData()
        const clientId = JSON.parse(localStorage.getItem("user")).clientId;
        const faceFrameBlob = sessionStorage.getItem('faceFrameBlob');

        faceFormData.append("client_id", clientId)
        faceFormData.append("face_frame", dataURItoBlob(faceFrameBlob), "face.png")
        faceFormData.append("id_frame", blob, "id.png")
        faceFormData.append("enroll_face", "false")

        await fetch(`https://api.voltox.ai/face-match`, {
            method: "POST",
            body: faceFormData,
        })
            .then(response => response.json())
            .then(async (data) => {
                // Check if faces match and there's no spoofing
                if (data.similarity_status === 1 && data.is_spoof === 0) {
                    //face matches
                    setFaceMatched(true);
                    checkIfDocumentsAreCompleted();
                }
                else {
                    //face didnt match
                    setFaceMatched(false);
                    setIsMatching(false);
                    setIsExtracting(false);
                }
            })
            .catch((error) => {
                console.log(error);
                setFaceMatched(false);
                setIsMatching(false);
                setIsExtracting(false);
            });
    }

    const handleOpen = (e) => {
        if (currentStep == 5) {
            setDocumentFrontPath(null);
            setFileFront(null);
        }
        else if (currentStep == 6) {
            setDocumentBackPath(null);
            setFileBack(null);
        }
        setOpen(e);
    }

    const checkIfDocumentsAreCompleted = () => {
        if (docType == "Identity Card" &&
            (
                (documentFrontPath && documentBackPath)
                || (fileFront && fileBack)
                || (documentFrontPath && fileBack)
                || (fileFront && documentBackPath)
            )
        ) {
            getKyc();
        }
        else if (docType != "Identity Card" && (documentFrontPath || fileFront)) {
            getKyc();
        }
    };

    const allGood = async () => {
        const formData = new FormData()
        const reqId = localStorage.getItem("reqId");
        let blob = new Blob();

        if (fileFront || fileBack) {
            blob = fileFront ? new Blob([fileFront], { type: fileFront.type }) : new Blob([fileBack], { type: fileBack.type });
        }
        else {
            blob = dataURItoBlob(currentStep == 5 ? documentFrontPath : documentBackPath);
        }

        const fileName = currentStep == 5 ? "captured-id-front.png" : "captured-id-back.png"
        formData.append("file", blob, fileName)
        formData.append("request_id", reqId)

        if (currentStep == 5) {
            fetch(`https://api.voltox.ai/upload-front-doc`, {
                method: "POST",
                body: formData,
            })
                .then((response) => response.json())
                .then((data) => {
                    // Instead of directly checking age, first check face match
                    checkFaceMatch(blob);
                })
                .catch((error) => {
                    console.log(error);
                })
        }

        if (currentStep == 6 && docType == "Identity Card") {
            await stopCamera();

            const formData = new FormData()
            const reqId = localStorage.getItem("reqId");

            formData.append("file", blob, fileName)
            formData.append("request_id", reqId)

            fetch(`https://api.voltox.ai/upload-back-doc`, {
                method: "POST",
                body: formData,
            })
                .then((response) => response.json())
                .then(async (data) => {
                    //successfully uploaded back doc too
                    checkIfDocumentsAreCompleted();
                })
                .catch((error) => {
                    console.log(error);
                })
        }

        setOpen(false);
        goToNextStep()
    };

    const DialogImg = () => {
        return (
            <Dialog open={open} onOpenChange={handleOpen}>
                <DialogContent className="sm:max-w-[425px]">
                    <DialogHeader>
                        <DialogTitle>{currentStep == 5 ? "Front" : "Back"} ID Image</DialogTitle>
                        <DialogDescription className="mt-3">
                            Looking good?
                        </DialogDescription>
                    </DialogHeader>
                    {currentStep == 5 ? <img className={`${isFrontCamera ? "flip" : "flipBack"} w-100`} style={{ height: "200px", objectFit: "cover" }} src={documentFrontPath} /> : <img className="flip w-100" style={{ height: "200px", objectFit: "cover" }} src={documentBackPath} />}
                    <div className="col pl-0 pr-0 mt-4 mb-2">
                        <button type="button" className="btn kycButton mb-3" onClick={() => { allGood(); }}>All Good</button>
                        <button type="button" className="btn kycButton kycButtonLighter" onClick={() => {
                            if (currentStep == 5) {
                                setDocumentFrontPath(null);
                                setFileFront(null);
                            }
                            else if (currentStep == 6) {
                                setDocumentBackPath(null);
                                setFileBack(null);
                            }
                            setOpen(false)
                        }}>Retake Picture</button>
                    </div>
                </DialogContent>
            </Dialog>
        )
    }

    const takePicture = () => {
        if (video.current) {
            // Create a canvas element
            const canvas = document.createElement("canvas");
            canvas.width = video.current.videoWidth;
            canvas.height = video.current.videoHeight;

            const context = canvas.getContext("2d");
            if (context) {
                context.drawImage(video.current, 0, 0, canvas.width, canvas.height);

                const dataURL = canvas.toDataURL("image/png");

                if (currentStep == 5) {
                    setDocumentFrontPath(dataURL);
                    setOpen(true);
                }
                else if (currentStep == 6) {
                    setDocumentBackPath(dataURL);
                    setOpen(true);
                }
            }
        }
    }

    const fileToBase64 = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            if (file) {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result as string);
                reader.onerror = (error) => reject(error);
            }
        });
    };

    //save kyc data
    const save = async () => {
        const userId = parseInt(JSON.parse(localStorage.getItem("user")).userId);

        extractedData.documentType = docType;
        // extractedData.documentFrontPath = documentFrontPath;
        // extractedData.documentBackPath = documentBackPath;
        //extractedData.extractedInformation = extractedInformation;

        //checkForErrors();

        let frontDoc = "";
        if (!documentFrontPath) {
            frontDoc = await fileToBase64(fileFront);
        }
        else {
            frontDoc = documentFrontPath;
        }

        let backDoc = "";
        if (docType == "Identity Card") {
            if (!documentBackPath) {
                backDoc = await fileToBase64(fileBack);
            }
            else {
                backDoc = documentBackPath;
            }
        }

        let dataToSend: IFormInput = {
            userId: userId,
            country: country,
            firstName: firstName,
            lastName: lastName,
            nationality: nationality,
            gender: gender,
            dateOfBirth: dateOfBirth,
            documentType: documentType,
            facePath: sessionStorage.getItem("faceFrameBlob"),
            documentFrontPath: frontDoc,
            documentBackPath: backDoc,
            extractedInformation: JSON.stringify(extractedData)
        }

        const response = await post("/api/User/SaveKycInformation", dataToSend) as any;

        if (!response?.flag) {
            toast.success(response.message);
            throw new Error(response.message);
        }

        fetchKycHistory();
        toast.success("Successfully saved!");
    }

    const options = countries.map((country) => ({
        value: country.code,
        label: country.name,
    }));

    const languageOptions = [
        { value: "en", label: "English" },
        // { code: "fr", label: "French" },
        // { code: "es", label: "Spanish" },
    ];

    const startInterval = () => {
        setScanPhase(2);

        const interval = setInterval(() => {
            setCountdown((prevCountdown) => {
                if (prevCountdown === 1) {
                    captured();
                    stopCamera();
                    clearInterval(interval);
                    return prevCountdown;
                }
                return prevCountdown - 1;
            });
        }, 1000);

        return () => clearInterval(interval);
    }

    const scanFace = async () => {
        resetFaceScan();
        setShowSpinner(true);
        await startCamera();
        setIsFaceScanning(true);
        startInterval();
    }

    const captured = () => {
        setWaitingForScan(true);
        const clientId = JSON.parse(localStorage.getItem("user")).clientId;

        const canvas = document.createElement("canvas");
        canvas.width = video.current.videoWidth;
        canvas.height = video.current.videoHeight;

        const context = canvas.getContext("2d");
        context.drawImage(video.current, 0, 0, canvas.width, canvas.height);

        const faceFrameDataUrl = canvas.toDataURL()
        sessionStorage.setItem('faceFrameBlob', faceFrameDataUrl)

        canvas.toBlob(function (blob) {
            if (blob) {
                const formData = new FormData()
                formData.append("file", blob, "captured-image.png")
                formData.append("client_id", clientId);

                fetch(`https://api.voltox.ai/get-age`, {
                    method: "POST",
                    body: formData,
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.status === "SUCCESS" && data.is_spoof === 0) {
                            localStorage.setItem("reqId", data.request_id)
                            setIsFaceDetected(true);
                        }
                        else {
                            setIsFaceDetected(false);
                        }

                        setScanPhase(3);
                        setWaitingForScan(false);
                        setIsFaceScanning(false);
                        setShowSpinner(false)
                    });
            }
            else {
                console.log("No blob...");
            }
        });
    }

    function formatKey(key) {
        return key
            .replace(/_/g, " ") // Replace underscores with spaces
            .split(" ") // Split into words
            .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter
            .join(" "); // Join back into a string
    }

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, type: string) => {
        const size = (event.target.files[0].size / (1024 * 1024));

        if (event.target.files && event.target.files[0]) {
            if (size <= 15) {
                if (type == "front") {
                    setFileFront(event.target.files[0]);
                }
                if (type == "back") {
                    setFileBack(event.target.files[0]);
                }
            }
            else {
                toast.error("Maximum file size is 3.5 MB");
            }
        }
    };

    const flipCamera = async () => {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter(device => device.kind === "videoinput");

        const backCamera = videoDevices.find(device => device.label.toLowerCase().includes("back"));
        const frontCamera = videoDevices.find(device => device.label.toLowerCase().includes("front"));

        const selectedDeviceId = isFrontCamera ? backCamera?.deviceId : frontCamera?.deviceId;
        if (!selectedDeviceId) return;

        setIsFrontCamera(prev => !prev);

        const constraints = {
            video: {
                deviceId: { exact: selectedDeviceId },
                width: { ideal: 4096 },
                height: { ideal: 2160 }
            }
        };

        if (video.current?.srcObject instanceof MediaStream) {
            video.current.srcObject.getTracks().forEach(track => track.stop());
        }

        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        if (video.current) {
            video.current.srcObject = stream;
        }

        // Apply flip effect
        if (!isFrontCamera) {
            video.current.classList.remove("flipBack");
            video.current.classList.add("flip");
        } else {
            video.current.classList.remove("flip");
            video.current.classList.add("flipBack");
        }
    };

    useEffect(() => {
        if (fileFront || fileBack) {
            allGood();
        }
    }, [fileFront, fileBack]);

    function fuzzyMatch(str1, str2, threshold = 0.8) {
        if (!str1 || !str2) return false;

        const distance = levenshteinDistance(str1.toLowerCase(), str2.toLowerCase());
        const maxLength = Math.max(str1.length, str2.length);
        const similarity = 1 - distance / maxLength; // Normalize similarity to 0-1 scale

        return similarity >= threshold;
    }

    // Levenshtein Distance Algorithm
    function levenshteinDistance(a, b) {
        const dp = Array.from({ length: a.length + 1 }, () => Array(b.length + 1).fill(0));

        for (let i = 0; i <= a.length; i++) dp[i][0] = i;
        for (let j = 0; j <= b.length; j++) dp[0][j] = j;

        for (let i = 1; i <= a.length; i++) {
            for (let j = 1; j <= b.length; j++) {
                dp[i][j] = Math.min(
                    dp[i - 1][j] + 1, // Deletion
                    dp[i][j - 1] + 1, // Insertion
                    dp[i - 1][j - 1] + (a[i - 1] === b[j - 1] ? 0 : 1) // Substitution
                );
            }
        }
        return dp[a.length][b.length];
    }

    return (
        <div className="space-y-4 fade-in h-100">
            <div className="d-flex flex-column flex-sm-row gap-4 justify-between">
                <h2 className="text-3xl font-bold tracking-tight">
                    KYC Verification
                </h2>
            </div>
            <div className="rounded-xl bg-card text-card-foreground mb-4"><div className="p-0 border rounded-lg shadow-none"></div></div>

            <div id="kycApp" className="row w-100 m-auto">
                <nav className="mobile-nav d-flex d-lg-none">
                    <div className="nav-logo">
                        <svg width="421" height="128" viewBox="0 0 421 128" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_101_777)">
                                <path d="M77.8193 36.0972L68.2618 66.5357C66.586 71.8043 65.0097 77.2684 63.5329 82.5353H61.2663C59.8874 77.2668 58.3111 71.8027 56.6352 66.5357L47.1741 36.0972H32L52.6928 94.6332H71.8094L92.4027 36.0972H77.8193Z" fill="#78FFB2"></path>
                                <path d="M195.297 83.5128C191.257 83.5128 189.878 81.6596 189.878 77.9518V58.9261H202.195V48.389H189.878V36.4882H176.476V48.3906H170.761V58.9277H176.476V80.0989C176.476 88.8799 181.009 96.0016 191.847 96.0016C196.971 96.0016 199.336 95.3182 202.883 94.2454L201.405 82.343C199.631 82.9279 197.365 83.5144 195.295 83.5144L195.297 83.5128Z" fill="#78FFB2"></path>
                                <path d="M249.754 82.8198C241.331 86.5547 232.024 87.7229 222.918 86.275C217.975 85.4883 213.004 83.7973 208.739 81.1066C211.823 90.5328 219.807 96 231.458 96C243.108 96 251.203 90.3087 254.159 80.5233C252.736 81.3751 251.266 82.1491 249.752 82.8198H249.754Z" fill="#78FFB2"></path>
                                <path d="M209.055 74.8447C209.283 75.037 209.519 75.2213 209.75 75.4105C209.364 75.0942 209.88 75.5058 210.018 75.6059C210.476 75.9365 210.943 76.2528 211.42 76.5532C212.412 77.181 213.444 77.7468 214.503 78.2553C214.744 78.3714 214.985 78.4842 215.229 78.5923C215.287 78.6177 215.609 78.7591 215.743 78.8179C215.865 78.8656 216.221 79.0103 216.282 79.0341C216.495 79.1167 216.711 79.1978 216.926 79.2773C217.895 79.6317 218.881 79.9464 219.876 80.2197C220.866 80.4931 221.868 80.7267 222.876 80.9222C222.928 80.9317 222.981 80.9397 223.032 80.9492C221.44 78.6892 220.719 75.4661 220.719 71.4118C220.719 63.0218 223.971 58.2411 231.361 58.2411C238.752 58.2411 242.002 63.0218 242.002 71.4118C242.002 75.2468 241.335 78.338 239.904 80.5757C241.1 80.3008 242.287 79.9813 243.455 79.6062C244.429 79.2932 245.393 78.9467 246.341 78.5652C246.441 78.5255 246.539 78.4842 246.638 78.4445C246.669 78.4302 246.702 78.4159 246.751 78.3952C246.958 78.303 247.166 78.2124 247.372 78.1186C247.82 77.9152 248.263 77.7054 248.703 77.4861C250.56 76.5627 252.345 75.4979 254.032 74.2964C254.247 74.1438 254.459 73.988 254.671 73.8307C254.709 73.8021 254.746 73.7735 254.785 73.7449C254.831 73.7083 254.905 73.6527 254.939 73.6257C255.08 73.5128 255.216 73.3968 255.356 73.2824C255.383 72.6991 255.403 72.1111 255.403 71.5103C255.403 56.0956 246.043 47.1191 231.459 47.1191C216.876 47.1191 207.318 56.0956 207.318 71.5103C207.318 72.0983 207.336 72.6737 207.363 73.2426C207.904 73.8005 208.463 74.3393 209.057 74.8415L209.055 74.8447Z" fill="#78FFB2"></path>
                                <path d="M291.766 72.3892L304.476 49.4634V48.3906H290.287L282.897 66.2449H281.123L273.042 48.3906H257.671V49.4634L270.875 70.4391L257.473 93.5604V94.6348H272.452L279.743 76.3895H281.418L289.695 94.6348H305.066V93.5604L291.766 72.3892Z" fill="#78FFB2"></path>
                                <path d="M106.854 86.2257C101.69 85.4041 96.5008 83.5907 92.1121 80.687C95.1058 90.3405 103.151 95.9507 114.954 95.9507C126.757 95.9507 134.52 90.3818 137.554 80.7935C136.301 81.5182 135.013 82.1841 133.69 82.7706C125.268 86.5055 115.961 87.6736 106.854 86.2257Z" fill="#78FFB2"></path>
                                <path d="M92.9918 74.7954C93.2197 74.9877 93.4557 75.1721 93.6868 75.3612C93.3 75.0449 93.8169 75.4566 93.9549 75.5567C94.4124 75.8873 94.8795 76.2035 95.3563 76.5039C96.3483 77.1317 97.3804 77.6975 98.4399 78.2061C98.6807 78.3221 98.9215 78.4349 99.1654 78.543C99.2232 78.5684 99.5459 78.7099 99.6791 78.7687C99.8011 78.8164 100.157 78.961 100.218 78.9848C100.432 79.0675 100.647 79.1485 100.862 79.228C101.832 79.5824 102.817 79.8971 103.813 80.1705C104.686 80.4104 105.57 80.6171 106.458 80.7982C104.915 78.543 104.214 75.358 104.214 71.3641C104.214 62.9741 107.466 58.1934 114.856 58.1934C122.247 58.1934 125.497 62.9741 125.497 71.3641C125.497 75.2674 124.804 78.3984 123.321 80.6441C124.693 80.3453 126.054 79.9861 127.392 79.557C128.366 79.2439 129.329 78.8974 130.278 78.516C130.377 78.4762 130.475 78.4349 130.575 78.3952C130.605 78.3809 130.639 78.3666 130.687 78.3459C130.894 78.2537 131.103 78.1632 131.308 78.0694C131.756 77.866 132.199 77.6562 132.639 77.4368C134.496 76.5134 136.281 75.4486 137.968 74.2471C138.183 74.0945 138.395 73.9388 138.607 73.7814C138.646 73.7528 138.683 73.7242 138.721 73.6956C138.752 73.6718 138.793 73.64 138.83 73.6114C138.872 72.9073 138.899 72.1937 138.899 71.461C138.899 56.0463 129.538 47.0699 114.954 47.0699C100.371 47.0699 90.8135 56.0463 90.8135 71.461C90.8135 71.8806 90.8231 72.2938 90.836 72.7055C91.5102 73.4445 92.2261 74.147 92.9934 74.7922L92.9918 74.7954Z" fill="#78FFB2"></path>
                                <path d="M147.781 70.9222C148.458 71.8123 148.417 73.1123 147.781 74.0182V94.6332H161.181V32H147.781V70.9222Z" fill="#78FFB2"></path>
                                <path d="M147.781 70.9222V74.0182C148.417 73.1123 148.458 71.8123 147.781 70.9222Z" fill="#78FFB2"></path>
                                <path d="M322.74 55.525C322.74 54.3712 323.231 53.3921 324.215 52.5927C325.197 51.7933 326.49 51.3928 328.088 51.3928C329.687 51.3928 330.959 51.7853 331.901 52.5689C332.842 53.3524 333.346 54.422 333.413 55.7729H330.488C330.439 55.0975 330.201 54.5682 329.776 54.19C329.35 53.8101 328.755 53.621 327.989 53.621C327.223 53.621 326.621 53.7895 326.19 54.128C325.756 54.4665 325.541 54.9115 325.541 55.4646C325.541 56.0177 325.816 56.45 326.366 56.7647C326.917 57.0778 327.586 57.3098 328.377 57.4576C329.169 57.6054 329.96 57.7882 330.753 58.0027C331.545 58.2173 332.216 58.6083 332.764 59.1772C333.315 59.7462 333.59 60.5107 333.59 61.4658C333.59 62.6531 333.086 63.6194 332.077 64.3616C331.069 65.1038 329.774 65.4757 328.19 65.4757C326.605 65.4757 325.323 65.0958 324.34 64.3361C323.358 63.578 322.815 62.4973 322.716 61.0955H325.665C325.714 61.7726 325.965 62.3002 326.414 62.6785C326.864 63.0583 327.477 63.2475 328.252 63.2475C329.028 63.2475 329.642 63.079 330.101 62.7405C330.559 62.4019 330.788 61.9522 330.788 61.3911C330.788 60.8301 330.514 60.3931 329.963 60.08C329.413 59.7669 328.743 59.5317 327.952 59.3743C327.161 59.2186 326.369 59.0358 325.578 58.8292C324.787 58.6226 324.116 58.2475 323.567 57.7024C323.016 57.1572 322.742 56.4325 322.742 55.525H322.74Z" fill="#78FFB2"></path>
                                <path d="M339.311 51.6153V53.7688C340.227 52.1843 341.693 51.3928 343.709 51.3928C344.775 51.3928 345.725 51.6407 346.558 52.135C347.391 52.6293 348.024 53.3476 348.457 54.2885C348.94 53.381 349.61 52.6722 350.468 52.1604C351.326 51.6487 352.297 51.3928 353.38 51.3928C354.963 51.3928 356.263 51.8998 357.279 52.9153C358.295 53.9309 358.804 55.3613 358.804 57.2097V65.2532H355.955V57.6308C355.955 56.4102 355.647 55.4741 355.03 54.8225C354.414 54.1709 353.573 53.8451 352.505 53.8451C351.438 53.8451 350.597 54.1709 349.98 54.8225C349.364 55.4741 349.056 56.4102 349.056 57.6308V65.2532H346.231V57.6308C346.231 56.4102 345.922 55.4741 345.306 54.8225C344.69 54.1709 343.847 53.8451 342.781 53.8451C341.715 53.8451 340.869 54.1709 340.243 54.8225C339.619 55.4741 339.306 56.4102 339.306 57.6308V65.2532H336.456V51.6169H339.306L339.311 51.6153Z" fill="#78FFB2"></path>
                                <path d="M365.93 48.0012C365.93 48.513 365.755 48.9421 365.405 49.2885C365.055 49.635 364.626 49.8082 364.118 49.8082C363.609 49.8082 363.18 49.635 362.83 49.2885C362.48 48.9421 362.305 48.513 362.305 48.0012C362.305 47.4894 362.48 47.0603 362.83 46.7138C363.18 46.3674 363.609 46.1941 364.118 46.1941C364.626 46.1941 365.055 46.3674 365.405 46.7138C365.755 47.0603 365.93 47.4894 365.93 48.0012ZM362.681 65.25V51.6137H365.53V65.25H362.681Z" fill="#78FFB2"></path>
                                <path d="M369.529 65.25V46.9379H372.378V65.25H369.529Z" fill="#78FFB2"></path>
                                <path d="M382.176 65.4725C380.176 65.4725 378.552 64.8288 377.303 63.5415C376.054 62.2541 375.428 60.5472 375.428 58.4191C375.428 56.291 376.06 54.5873 377.327 53.3095C378.593 52.0317 380.231 51.3912 382.239 51.3912C384.247 51.3912 385.876 52.0063 387.125 53.2348C388.376 54.4633 389 56.0685 389 58.0488C389 58.5272 388.966 58.9722 388.9 59.3854H378.353C378.418 60.525 378.807 61.4277 379.515 62.0952C380.223 62.7627 381.11 63.0965 382.178 63.0965C383.727 63.0965 384.818 62.4623 385.451 61.1909H388.526C388.109 62.4449 387.351 63.4716 386.252 64.271C385.152 65.072 383.794 65.4709 382.178 65.4709L382.176 65.4725ZM384.9 54.7685C384.167 54.101 383.276 53.7672 382.226 53.7672C381.176 53.7672 380.297 54.101 379.589 54.7685C378.881 55.436 378.476 56.3324 378.377 57.4544H386.026C386.008 56.3324 385.634 55.4376 384.9 54.7685Z" fill="#78FFB2"></path>
                                <path d="M330.039 81.1368H326.69V88.6844C326.69 89.1962 326.811 89.5633 327.053 89.7858C327.294 90.0083 327.706 90.1195 328.291 90.1195H330.04V92.4701H327.792C325.141 92.4701 323.817 91.2082 323.817 88.6844V81.1368H322.193V78.8354H323.817V75.4454H326.692V78.8354H330.04V81.1368H330.039Z" fill="#78FFB2"></path>
                                <path d="M349.359 78.8354H352.159L347.884 92.4717H344.885L342.11 82.4003L339.336 92.4717H336.338L332.039 78.8354H334.938L337.811 89.7985L340.736 78.8354H343.71L346.51 89.7492L349.359 78.8354Z" fill="#78FFB2"></path>
                                <path d="M357.782 75.2229C357.782 75.7347 357.607 76.1638 357.257 76.5103C356.907 76.8567 356.478 77.03 355.969 77.03C355.461 77.03 355.032 76.8567 354.682 76.5103C354.332 76.1638 354.157 75.7347 354.157 75.2229C354.157 74.7112 354.332 74.2821 354.682 73.9356C355.032 73.5891 355.461 73.4159 355.969 73.4159C356.478 73.4159 356.907 73.5891 357.257 73.9356C357.607 74.2821 357.782 74.7112 357.782 75.2229ZM354.533 92.4717V78.8354H357.382V92.4717H354.533Z" fill="#78FFB2"></path>
                                <path d="M361.806 90.7648C360.556 89.4775 359.931 87.7753 359.931 85.6552C359.931 83.535 360.56 81.8313 361.817 80.5439C363.074 79.2566 364.695 78.6129 366.679 78.6129C368.346 78.6129 369.725 79.0055 370.816 79.789C371.908 80.5725 372.644 81.6914 373.028 83.1425H369.952C369.436 81.7073 368.344 80.9905 366.679 80.9905C365.512 80.9905 364.583 81.399 363.893 82.2159C363.201 83.0328 362.856 84.1755 362.856 85.644C362.856 87.1126 363.201 88.2585 363.893 89.0833C364.583 89.9082 365.512 90.3214 366.679 90.3214C368.33 90.3214 369.42 89.6046 369.952 88.1695H373.028C372.628 89.5553 371.877 90.6567 370.778 91.4736C369.678 92.2905 368.312 92.699 366.679 92.699C364.679 92.699 363.055 92.0553 361.806 90.768V90.7648Z" fill="#78FFB2"></path>
                                <path d="M381.427 92.6942C379.427 92.6942 377.802 92.0506 376.553 90.7632C375.303 89.4759 374.678 87.769 374.678 85.6409C374.678 83.5128 375.311 81.809 376.577 80.5312C377.844 79.2534 379.481 78.6129 381.489 78.6129C383.497 78.6129 385.127 79.228 386.375 80.4565C387.626 81.6851 388.25 83.2903 388.25 85.2706C388.25 85.7489 388.217 86.1939 388.151 86.6072H377.603C377.669 87.7467 378.057 88.6494 378.765 89.3169C379.473 89.9845 380.361 90.3182 381.428 90.3182C382.977 90.3182 384.069 89.6841 384.701 88.4126H387.777C387.359 89.6666 386.602 90.6933 385.502 91.4927C384.403 92.2937 383.045 92.6926 381.428 92.6926L381.427 92.6942ZM384.151 81.9902C383.417 81.3227 382.526 80.9889 381.476 80.9889C380.427 80.9889 379.547 81.3227 378.839 81.9902C378.131 82.6577 377.727 83.5541 377.627 84.6761H385.276C385.258 83.5541 384.884 82.6593 384.151 81.9902Z" fill="#78FFB2"></path>
                            </g>
                            <defs>
                                <clipPath id="clip0_101_777">
                                    <rect width="357" height="64" fill="white" transform="translate(32 32)"></rect>
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                </nav>
                <div id="leftSide" className="col-6 d-none d-lg-flex"><img src={backgroundImg} style={{ objectFit: "cover" }} alt="Banner"></img></div>
                <div id="rightSide" className="col-12 col-lg-6">
                    <form onSubmit={handleSubmit(save)} className="container pl-0 pr-0 d-flex flex-column align-items-center justify-content-center h-100" style={{ width: "90%" }}>
                        {currentStep == 0 && <div className="w-100">
                            <h1 className="kycTitle green">Welcome to Voltox KYC Verification</h1>
                            <p className="kycDescription green mt-4 mb-4">Please select your preferred language to continue.</p>

                            {fieldErrors.language && <Label htmlFor="Language" className={fieldErrors.language ? "red text-left col-2 me-2 w-100 mb-2" : "text-left col-2 me-2 w-100 mb-2 green"}>
                                {fieldErrors.language}
                            </Label>
                            }
                            <Select
                                id="Language"
                                isClearable={true}
                                styles={{
                                    option: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: state.isSelected ? "#1b5257" : "white",
                                        fontSize: "16px"
                                    }),
                                    control: (baseStyles) => ({
                                        ...baseStyles,
                                        height: "45px",
                                        borderColor: '#1b5257',
                                        fontSize: "16px"
                                    }),
                                }}
                                className={fieldErrors.language ? 'is-invalid kycSelect red mb-4' : 'kycSelect green mb-4'}
                                options={languageOptions}
                                onChange={(e) => setValue("language", (e != null ? e.label : null))}
                            />
                        </div>
                        }
                        {currentStep == 1 && <div className="w-100">
                            <h1 className="kycTitle green">Personal Info</h1>
                            <p className="kycDescription green mt-4 mb-4">Please confirm your information.</p>
                            <div className="row">
                                <div className="col-6">
                                    <Label htmlFor="FirstName" className={fieldErrors.firstName ? "red text-left col-2 me-2 w-100 mb-2" : "text-left col-2 me-2 w-100 mb-2 green"}>
                                        {fieldErrors.firstName ?? "First Name"}
                                    </Label>
                                    <input type="text" className={fieldErrors.firstName ? 'form-control is-invalid kycInput' : 'form-control kycInput'} placeholder="Your First Name" {...register("firstName")} />
                                </div>
                                <div className="col-6">
                                    <Label htmlFor="LastName" className={fieldErrors.lastName ? "red text-left col-2 me-2 w-100 mb-2" : "text-left col-2 me-2 w-100 mb-2 green"}>
                                        {fieldErrors.lastName ?? "Last Name"}
                                    </Label>
                                    <input name="LastName" type="text" className={fieldErrors.lastName ? 'form-control is-invalid kycInput' : 'form-control kycInput'} placeholder="Your Last Name" {...register("lastName")} />
                                </div>
                                <div className="col-12 mt-3">
                                    <Label htmlFor="Nationality" className={fieldErrors.nationality ? "red text-left col-2 me-2 w-100 mb-2" : "text-left col-2 me-2 w-100 mb-2 green"}>
                                        {fieldErrors.nationality ?? "Nationality"}
                                    </Label>
                                    <input name="Nationality" type="text" className={fieldErrors.nationality ? 'form-control is-invalid kycInput' : 'form-control kycInput'} placeholder="Your Nationality" {...register("nationality")} />
                                </div>
                                <div className="col-6 mt-3">
                                    <Label htmlFor="Gender" className={fieldErrors.gender ? "red text-left col-2 me-2 w-100 mb-2" : "text-left col-2 me-2 w-100 mb-2 green"}>
                                        {fieldErrors.gender ?? "Gender"}
                                    </Label>
                                    <select
                                        id="Gender"
                                        style={{ height: "45px", fontSize: "16px" }}
                                        className={fieldErrors.gender ? 'form-select is-invalid kycSelect red' : 'form-select borderGreen kycSelect green'}
                                        {...register("gender")}
                                    >
                                        <option value="">Select a Gender</option>
                                        <option value="M">Male</option>
                                        <option value="F">Female</option>
                                    </select>
                                </div>
                                <div className="col-6 mt-3">
                                    <Label htmlFor="DateOfBirth" className={fieldErrors.dateOfBirth ? "red text-left col-2 me-2 w-100 mb-2" : "text-left col-2 me-2 w-100 mb-2 green"}>
                                        {fieldErrors.dateOfBirth ?? "Date Of Birth"}
                                    </Label>
                                    <input name="DateOfBirth" type="date" className={fieldErrors.dateOfBirth ? 'form-control is-invalid kycInput' : 'form-control kycInput'} {...register("dateOfBirth")} />
                                </div>
                            </div>
                            <p className="mt-4 mb-4 green needHelp">Need Help?</p>
                        </div>
                        }
                        {currentStep == 2 && <div className="w-100">
                            {(scanPhase == 1 || scanPhase == 2) && <h1 className="kycTitle green">{isFaceScanning ? "Scanning In Progress" : "Scan Your Face"}</h1>}
                            {scanPhase == 3 &&
                                (isFaceDetected && <h1 className="kycTitle green">Face secured—looking good!</h1>) ||
                                (!isFaceDetected && <h1 className="kycTitle green">Where’d your face go?</h1>)
                            }
                            <p className="kycDescription green mt-4">
                                {!isFaceScanning && scanPhase == 1 && "We will use your face to match the documents you provide in the next steps."}
                                {isFaceScanning && scanPhase == 2 && (waitingForScan ? "👀 We're checking to make sure we’ve got a real human face. This won’t take long!" : "Please hold still while we capture your face. This will only take a moment!")}
                                {!isFaceScanning && scanPhase == 3 && isFaceDetected && "We’ve captured your fabulous face—move over, superstar! 🎉 You're ready for the next steps."}
                                {!isFaceScanning && scanPhase == 3 && !isFaceDetected && "Oops! It seems like the camera couldn't find your lovely face—are you hiding? 🕵️‍♂️ Please make sure your face is visible and try again!"}
                            </p>

                            <div className={!isFaceDetected ? "camera-viewport-red position-relative overflow-hidden mt-4 mb-4" : "camera-viewport position-relative overflow-hidden mt-4 mb-4"}>
                                <video
                                    className="video-id flip"
                                    style={{ height: "220px" }}
                                    playsInline
                                    autoPlay
                                    ref={video}
                                ></video>

                                {!isFaceScanning && scanPhase == 1 && <CameraGuideIcon />}
                                {!isFaceScanning && scanPhase == 3 && isFaceDetected && <CheckIcon className="camera-guide" />}
                                {!isFaceScanning && scanPhase == 3 && !isFaceDetected && <XIcon className="camera-guide" />}
                            </div>

                            {isFaceScanning && <p className="kycDescription green text-center">{!waitingForScan ? countdown : ""}</p>}

                            {!isFaceScanning && !showSpinner && <div className="col pl-0 pr-0 mb-2">
                                {isFaceDetected &&
                                    <button type="button" className="btn kycButton" onClick={!isFaceScanning && scanPhase == 1 ? scanFace : goToNextStep}>
                                        {<span>{scanPhase != 1 ? "Continue" : "Get Started"}</span>}
                                    </button>
                                }
                                {!isFaceDetected &&
                                    <button type="button" className="btn kycButton" onClick={scanFace}>
                                        <span>Retry</span>
                                    </button>
                                }
                            </div>
                            }
                        </div>
                        }
                        {currentStep == 3 && <div className="w-100">
                            <h1 className="kycTitle green">Select Your Country</h1>
                            <p className="kycDescription green mt-4 mb-4">Choose your country to proceed with the identification process.</p>
                            {fieldErrors.country && <Label htmlFor="Country" className={fieldErrors.country ? "red text-left col-2 me-2 w-100 mb-2" : "text-left col-2 me-2 w-100 mb-2 green"}>
                                {fieldErrors.country}
                            </Label>
                            }
                            {/* <select
                                id="Country"
                                style={{ height: "45px" }}
                                className={fieldErrors.country ? 'form-select is-invalid kycSelect red' : 'form-select borderGreen kycSelect green'}
                                {...register("country")}
                            >
                                <option value="">Select a Country</option>
                                {countries.map((x: any) => {
                                    return <option value={x.isoCode}>{x.name}</option>
                                })}
                            </select> */}
                            <Select
                                id="Country"
                                isClearable={true}
                                styles={{
                                    option: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: state.isSelected ? "#1b5257" : "white",
                                        fontSize: "16px"
                                    }),
                                    control: (baseStyles) => ({
                                        ...baseStyles,
                                        height: "45px",
                                        borderColor: '#1b5257',
                                        fontSize: "16px"
                                    }),
                                }}
                                className={fieldErrors.country ? 'is-invalid kycSelect red' : 'kycSelect green'}
                                options={options}
                                onChange={(e) => {
                                    setValue("country", (e != null ? e.label : null));
                                    setCountryCode(e.value);
                                }}
                            />
                            <p className="mt-4 mb-4 green needHelp">Need Help?</p>
                        </div>
                        }
                        {currentStep == 4 && <div className="w-100">
                            <h1 className="kycTitle green">Scan ID</h1>
                            <p className="kycDescription green mt-4 mb-4">Select a piece of ID to scan.</p>

                            <Label htmlFor="DocumentType" className={"red text-left col-2 me-2 w-100 mb-4"}>
                                {fieldErrors.documentType}
                            </Label>

                            <div className="row">
                                <div className="col-12">
                                    <div className={documentType == "Identity Card" ? "graySquare active" : "graySquare"} onClick={selectIdPiece}>
                                        <h1>Identity Card</h1>
                                    </div>
                                </div>
                                <div className="col-12 mt-4 mb-4"><h2 id="orLine"><span>OR</span></h2></div>
                                <div className="col-12">
                                    <div className={documentType == "Driver's License" ? "graySquare active" : "graySquare"} onClick={selectIdPiece}>
                                        <h1>Driver's License</h1>
                                    </div>
                                </div>
                                <div className="col-12 mt-4 mb-4"><h2 id="orLine"><span>OR</span></h2></div>
                                <div className="col-12">
                                    <div className={documentType == "Passport" ? "graySquare active" : "graySquare"} onClick={selectIdPiece}>
                                        <h1>Passport</h1>
                                    </div>
                                </div>
                            </div>
                            <p className="mt-4 mb-4 green needHelp">Need Help?</p>
                        </div>
                        }
                        {currentStep == 5 && <div className="w-100">
                            <h1 className="kycTitle green">Scan ID</h1>
                            <p className="kycDescription green mt-4 mb-4">
                                {documentType == "Identity Card" ? <>1 / 2 <br /><br /></> : ""}
                                Please place <b>front</b> side inside the box.
                                <br />Make sure to have good lighting.
                            </p>

                            <div className="frame">
                                {!cameraStarted &&
                                    <div className="spinner" style={{ width: "60px", height: "60px", margin: "70px auto 0" }}></div>
                                }
                                {cameraStarted &&
                                    <div className="frameTextCenter">Front Side</div>
                                }
                                <video
                                    className="video-id flip"
                                    playsInline
                                    autoPlay
                                    ref={video}
                                ></video>
                            </div>

                            <button className="btn kycButton kycButtonLighter mt-3" type="button" onClick={flipCamera}>Flip Camera</button>

                            <p className="mt-4 mb-4 green needHelp">Need Help?</p>

                            <div className="row w-100">
                                {!documentFrontPath && cameraStarted && !fileFront && <div className="col pl-0 pr-2 mb-2">
                                    <input type="file" onChange={(e) => handleFileChange(e, "front")} ref={fileInputRef} style={{ display: "none" }} />
                                    <button type="button" className="btn kycButton" onClick={() => fileInputRef.current.click()}>Upload Document</button>
                                </div>
                                }
                                {!documentFrontPath && cameraStarted && <div className="col pl-2 pr-0 mb-2">
                                    <button type="button" className="btn kycButton" onClick={takePicture}>Take Picture</button>
                                </div>
                                }
                            </div>
                        </div>
                        }
                        {currentStep == 6 && <div className="w-100">
                            <h1 className="kycTitle green">Scan ID</h1>
                            <p className="kycDescription green mt-4 mb-4">2/2 <br /><br />
                                Now place <b>back</b> side of card inside the box.
                            </p>

                            <div className="frame">
                                {!cameraStarted &&
                                    <div className="spinner mb-5"></div>
                                }
                                {cameraStarted &&
                                    <div className="frameTextCenter">Back Side</div>
                                }
                                <video
                                    className="video-id flip"
                                    playsInline
                                    autoPlay
                                    ref={video}
                                ></video>
                            </div>

                            <button className="btn kycButton kycButtonLighter mt-3" onClick={flipCamera}>Flip Camera</button>

                            <p className="mt-4 mb-4 green needHelp">Need Help?</p>

                            <div className="row w-100">
                                {!fileBack &&
                                    <div className="col pl-0 pr-2 mt-4 mb-2">
                                        <input type="file" onChange={(e) => handleFileChange(e, "back")} ref={fileInputRef} style={{ display: "none" }} />
                                        <button type="button" className="btn kycButton" onClick={() => fileInputRef.current.click()}>Upload Document</button>
                                    </div>
                                }
                                <div className="col pl-2 pr-0 mt-4">
                                    <button type="button" className="btn kycButton" onClick={takePicture}>Take Picture</button>
                                </div>
                            </div>
                        </div>
                        }
                        {currentStep == 7 && <div className="w-100">
                            {isExtracting &&
                                <>
                                    <div className="spinner mb-4"></div>
                                    <p className="mt-5 mb-5 green text-center">📄 Give us a sec—we're squeezing all the important details out of your document like a juice press!</p>
                                </>
                            }
                            {!isExtracting && isMatching &&
                                <>
                                    <div className="spinner mb-4"></div>
                                    {/* <p className="mt-5 mb-5 green text-center">✅ Verifying your information... <br /><br /> We're making sure everything matches up!</p> */}
                                    {/* <p className="mt-5 mb-5 green text-center"> Matching your information... <br /><br /> We're making sure everything matches up!</p> */}
                                    <p className="mt-5 mb-5 green text-center"> 🕵️‍♂️ Comparing your info like a detective solving the easiest case ever!</p>
                                </>
                            }
                            {!isExtracting && !isMatching &&
                                <>
                                    {!extractSuccessful &&
                                        <>
                                            <XIcon className="bigXIcon mt-5 mb-2" stroke="#f8e2e1" strokeWidth="0.5" />
                                            <p className="mb-4 green text-center" style={{ fontSize: "40px", fontWeight: "700", color: "#a55450" }}>Failed!</p>
                                            {faceMatched && !isExpired && <p className="mb-4 green text-center">We couldn’t extract the information from your document. <br /><br />Please ensure it's clear, well-lit, and fully visible, then try again.</p>}
                                            {!faceMatched && !isExpired && <p className="mb-4 green text-center">The face scan doesn't match the one on your document.<br /><br />Please make sure you're looking directly at the camera and try again!</p>}
                                            {!faceMatched && isExpired && <p className="mb-4 green text-center">📜 Uh-oh! This document has seen better days—looks like it's expired! <br /><br /> Please upload a valid, up-to-date document to continue.</p>}
                                            <button type="button" className="btn kycButton" onClick={() => goToPreviousStep(4)}>Retry</button>
                                        </>
                                    }
                                    {extractSuccessful &&
                                        <>
                                            <CheckIcon className="bigCheckIcon mt-5 mb-2" stroke="#e4fff0" strokeWidth="0.9" />
                                            <p className="mb-4 green text-center" style={{ fontSize: "40px", fontWeight: "700" }}>Success!</p>
                                            <p className="mb-4 green text-center">You have successfully verified your identity!</p>
                                            <button type="button" className="btn kycButton" onClick={goToNextStep}>Finish</button>
                                        </>
                                    }
                                </>
                            }
                        </div>
                        }
                        {currentStep == 8 &&
                            <>
                                <p className="kycTitle green align-self-baseline mb-2" style={{ fontWeight: "700" }}>Extracted Data</p>
                                <p className="kycDescription green mb-4">Below is the information extracted from your document. Please make sure everything looks correct.</p>
                                <div className="row" style={{ width: "100%", minHeight: "300px"/*, border: "1px solid grey"*/ }}>
                                    <div className="col-4 pl-0 pr-5" style={{ borderRight: "1px solid rgb(121 121 121 / 10%)" }}>
                                        <div id="userPhoto" className="pb-3 mt-1" style={{ borderBottom: "1px dotted rgb(121 121 121 / 21%)" }}>
                                            <strong>Face Scanned</strong>
                                            <img className="flip" src={window.URL.createObjectURL(dataURItoBlob(sessionStorage.getItem("faceFrameBlob")))} style={{ width: "200px", height: "100px", objectFit: "cover", marginTop: "10px", border: "2px solid #068a82" }} />
                                        </div>
                                        <div id="idPhoto">
                                            {(documentFrontPath || fileFront) &&
                                                <div id="frontIdPhoto" className="pb-3 mt-2" style={{ borderBottom: "1px dotted rgb(121 121 121 / 21%)" }}>
                                                    <strong>Front ID</strong>
                                                    <img src={documentFrontPath ? window.URL.createObjectURL(dataURItoBlob(documentFrontPath)) : window.URL.createObjectURL(new Blob([fileFront], { type: fileFront.type }))}
                                                        style={{ width: "200px", height: "100px", marginTop: "10px", border: "2px solid #068a82", objectFit: "cover" }}
                                                    />
                                                </div>
                                            }

                                            {docType == "Identity Card" && (documentBackPath || fileBack) &&
                                                <div id="backIdPhoto" className="mt-2">
                                                    <strong>Back ID</strong>
                                                    <img src={documentBackPath ? window.URL.createObjectURL(dataURItoBlob(documentBackPath)) : window.URL.createObjectURL(new Blob([fileBack], { type: fileBack.type }))}
                                                        style={{ width: "200px", height: "100px", marginTop: "10px", border: "2px solid #068a82", objectFit: "cover" }}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-8 pr-0 pl-5">
                                        <div id="dataExtracted" style={{ width: "100%", height: "100%" }}>
                                            <ul>
                                                {extractedData && Object.keys(extractedData).map(key => {
                                                    if (key == "expiry_date" || key == "dob") {
                                                        return (
                                                            <li key={key} style={{ borderBottom: "1px dotted rgb(121 121 121 / 21%)" }}>
                                                                <strong style={{ textTransform: "capitalize" }}>{formatKey(key)}: </strong>
                                                                <span className="green">{new Date(extractedData[key]).toLocaleString().split(",")[0]}</span>
                                                            </li>
                                                        );
                                                    }
                                                    if (extractedData[key] === "id_card") {
                                                        return (
                                                            <li key={key} style={{ borderBottom: "1px dotted rgb(121 121 121 / 21%)" }}>
                                                                <strong style={{ textTransform: "capitalize" }}>{formatKey(key)}: </strong>
                                                                <span className="green">Identity Card</span>
                                                            </li>
                                                        );
                                                    }
                                                    else if (extractedData[key] === "driving_license") {
                                                        return (
                                                            <li key={key} style={{ borderBottom: "1px dotted rgb(121 121 121 / 21%)" }}>
                                                                <strong style={{ textTransform: "capitalize" }}>{formatKey(key)}: </strong>
                                                                <span className="green">Driver's License</span>
                                                            </li>
                                                        );
                                                    }
                                                    else if (extractedData[key] === "passport") {
                                                        return (
                                                            <li key={key} style={{ borderBottom: "1px dotted rgb(121 121 121 / 21%)" }}>
                                                                <strong style={{ textTransform: "capitalize" }}>{formatKey(key)}: </strong>
                                                                <span className="green">Passport</span>
                                                            </li>
                                                        );
                                                    }
                                                    else if (extractedData[key] === "M") {
                                                        return (
                                                            <li key={key} style={{ borderBottom: "1px dotted rgb(121 121 121 / 21%)" }}>
                                                                <strong style={{ textTransform: "capitalize" }}>{formatKey(key)}: </strong>
                                                                <span className="green">Male</span>
                                                            </li>
                                                        );
                                                    }
                                                    else if (extractedData[key] === "F") {
                                                        return (
                                                            <li key={key} style={{ borderBottom: "1px dotted rgb(121 121 121 / 21%)" }}>
                                                                <strong style={{ textTransform: "capitalize" }}>{formatKey(key)}: </strong>
                                                                <span className="green">Female</span>
                                                            </li>
                                                        );
                                                    }
                                                    else {
                                                        if (key != "mrz_validator" && key != "documentType") {
                                                            return (
                                                                <li key={key} style={{ borderBottom: "1px dotted rgb(121 121 121 / 21%)" }}>
                                                                    <strong style={{ textTransform: "capitalize" }}>{formatKey(key)}: </strong>
                                                                    <span className="green">{extractedData[key]}</span>
                                                                </li>
                                                            );
                                                        }
                                                    }
                                                })}

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                        <div id="buttons" className="row w-100 pb-4">
                            {(currentStep > 0 && currentStep < 6) && (currentStep == 5 ? cameraStarted : true) && !isFaceScanning && !showSpinner &&
                                <div className="col pl-0 pr-0">
                                    <button type="button" className="btn kycButton kycButtonLighter" onClick={() => goToPreviousStep()}>Go Back</button>
                                </div>
                            }
                            {currentStep != 2 && currentStep != 5 && currentStep != 6 && currentStep != 7 && currentStep != 8 &&
                                <div className="col pl-0 pr-0">
                                    <button type={currentStep == 7 ? "submit" : "button"} className="btn kycButton" onClick={goToNextStep}>Continue</button>
                                </div>
                            }
                        </div>
                    </form>
                </div>
            </div>
            <DialogImg />
            <KycModal />
            <br />
            <br />
            <br />

            {/* {frShouldShow && */}
            <div className="flex items-center justify-between">
                <h2 className="text-3xl font-bold tracking-tight">
                    KYC Verification History
                </h2>
            </div>
            {/* } */}

            {/* {frShouldShow && */}
            <Card>
                <CardContent className="p-0 border rounded-lg shadow-none">
                </CardContent>
            </Card>
            {/* } */}

            {/* {frShouldShow && */}
            <Card>
                <CardContent className="p-0 border rounded-lg shadow-none">
                    {kycData == null &&
                        <div className="text-center text-gray-500 p-4">No KYC history found</div>
                    }
                    <DataTable
                        data={kycData.sort((a: any, b: any) => new Date(b.insertDateTime).getTime() - new Date(a.insertDateTime).getTime())}
                        columns={columns}
                        isLoading={isLoading}
                    />
                </CardContent>
            </Card>
            {/* } */}
        </div>
    )
}