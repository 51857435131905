import { LoginFormValues } from "@/pages/Login";
import { SimpleDecodedToken } from "@/types/auth.types";
import axios from "axios";
import { useUser } from "../contexts/UserContext";
import { tokenService } from "./token.service";
import { userService } from "./user.service";

const BASE_URL = process.env.REACT_APP_API_URL;
//const BASE_URL = process.env.REACT_APP_API_LOCAL_URL;

// Configure axios defaults
const axiosInstance = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    }
});

// Add authorization header to all requests if token exists
axiosInstance.interceptors.request.use((config) => {
    const tokens = tokenService.getTokens();
    if (tokens?.token) {
        config.headers.Authorization = `Bearer ${tokens.token}`;
    }
    return config;
});

type RequestMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';

interface RequestOptions {
    method?: RequestMethod;
    headers?: Record<string, string>;
    body?: any;
}

interface RequestOptions {
    method?: RequestMethod;
    headers?: Record<string, string>;
    body?: any;
}

const CheckForRefreshToken = async (user: SimpleDecodedToken | null, setUser: any) => {
    if (user?.expirationTime) {
        let time = user?.expirationTime * 1000;

        if (time < Date.now()) {
            console.log("Expired, refreshing now...");
            let refreshToken = localStorage.getItem("refreshToken");

            if (refreshToken && refreshToken != "null") {
                await authService.refreshToken(refreshToken).then((response: any) => {
                    tokenService.setTokens({
                        token: response.data.token,
                        refreshToken: response.data.refreshToken
                    });

                    const decodedToken = userService.decodeToken(response.data.token);
                    const simplifiedToken = userService.simplifyDecodedToken(decodedToken);
                    setUser(simplifiedToken);
                });
            }
        }
    }
}

export const useApiService = () => {
    const { user, setUser } = useUser(); // Extract user and setUser from the context
    const baseUrl = process.env.REACT_APP_API_URL || "";
    //const baseUrl = process.env.REACT_APP_API_LOCAL_URL || "";

    const request = async <T>(endpoint: string, options: RequestOptions = {}): Promise<T> => {
        const { method = "GET", headers = {}, body } = options;

        const defaultHeaders: Record<string, string> = {
            Accept: "application/json",
        };

        // Add Content-Type if body is not FormData
        if (!(body instanceof FormData)) {
            defaultHeaders["Content-Type"] = "application/json";
        }

        const token = localStorage.getItem("token");
        if (token) {
            defaultHeaders["Authorization"] = `Bearer ${token}`;
        }

        await CheckForRefreshToken(user, setUser);

        const response = await fetch(`${baseUrl}${endpoint}`, {
            method,
            headers: { ...defaultHeaders, ...headers },
            body: body instanceof FormData ? body : JSON.stringify(body),
        });

        if (!response.ok) {
            const error = await response.json().catch(() => ({}));
            throw new Error(error.message || "An error occurred");
        }

        return response.json();
    };

    const get = async <T>(endpoint: string, headers?: Record<string, string>, needsBearer?: boolean): Promise<T> => {
        await CheckForRefreshToken(user, setUser);

        const token = localStorage.getItem("token");
        const authHeaders = needsBearer
            ? { ...headers, Authorization: `Bearer ${token}` }
            : headers;
        return request<T>(endpoint, { headers: authHeaders });
    };

    const post = async <T>(endpoint: string, data?: any, headers?: Record<string, string>): Promise<T> => {
        await CheckForRefreshToken(user, setUser);

        return request<T>(endpoint, {
            method: "POST",
            headers,
            body: data,
        });
    };

    const put = async <T>(endpoint: string, data: any, headers?: Record<string, string>): Promise<T> => {
        await CheckForRefreshToken(user, setUser);

        return request<T>(endpoint, {
            method: "PUT",
            headers,
            body: data,
        });
    };

    const del = async <T>(endpoint: string, data: any, headers?: Record<string, string>): Promise<T> => {
        await CheckForRefreshToken(user, setUser);

        return request<T>(endpoint, {
            method: "DELETE",
            headers,
        });
    };

    // Return API methods
    return { get, post, put, del };
};

// Add the response type interface
interface LoginResponse {
    flag: boolean;
    message: string;
    tokens: {
        token: string;
        refreshToken: string;
    };
}

interface AuthenticatedResponse {
    token?: string;
    refreshToken?: string;
}

interface RegisterResponse {
    flag: boolean;
    message: string;
}

// Add this interface with the other interfaces
interface ForgotPasswordResponse {
    flag: boolean;
    message: string;
}

// Add this interface with the other interfaces
interface ResetPasswordResponse {
    flag: boolean;
    message: string;
}

// Add this interface with the other interfaces
interface ResendConfirmationResponse {
    flag: boolean;
    message: string;
}

// Auth service methods
export const authService = {
    login: (credentials: LoginFormValues) => {
        return axiosInstance.post<LoginResponse>('/api/User/login', credentials);
    },

    signup: (formData: FormData) => {
        return axiosInstance.post<RegisterResponse>('/api/User/register', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    },

    refreshToken: (refreshToken: string) => {
        return axiosInstance.post<AuthenticatedResponse>('/api/User/RefreshToken', refreshToken);
    },

    logout() {
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('user');
        localStorage.removeItem('reqId');
        sessionStorage.removeItem('faceFrameBlob');
        sessionStorage.removeItem('sidebar');
    },

    forgotPassword: (email: string) => {
        return axiosInstance.post<ForgotPasswordResponse>(`/api/User/ForgotPassword?email=${email}`, {
            email: email
        });
    },

    resetPassword: (token: string, password: string) => {
        return axiosInstance.post<ResetPasswordResponse>(`/api/User/SetNewPassword?token=${token}&password=${password}`, {
            token,
            password
        });
    },

    resendConfirmationEmail: (email: string) => {
        return axiosInstance.get<ResendConfirmationResponse>(`/api/User/ResendConfirmationEmail?email=${email}`);
    },
};

// Update the interceptor to use axiosInstance
axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        if (error.response?.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            const tokens = tokenService.getTokens();
            if (tokens?.refreshToken) {
                try {
                    const response = await authService.refreshToken(tokens.refreshToken);
                    if (response.data.token && response.data.refreshToken) {
                        tokens.token = response.data.token;
                        tokens.refreshToken = response.data.refreshToken;
                        tokenService.setTokens(tokens);
                        originalRequest.headers['Authorization'] = `Bearer ${response.data.token}`;
                        return axiosInstance(originalRequest);
                    }
                } catch (refreshError) {
                    tokenService.clearTokens();
                    window.location.href = '/login';
                }
            }
        }
        return Promise.reject(error);
    }
);

export { axiosInstance };
