import axios from "axios";

export interface KycData {
    userId?: number;
    country: string;
    firstName: string;
    lastName: string;
    nationality: string;
    gender: string;
    dateOfBirth: Date;
    documentType: string;
    documentFrontPath: string;
    documentBackPath: string;
    extractedInformation: string;
    insertedDateTime: Date;
}

export const kycService = {
    async getKycHistory(clientId: string): Promise<any> {
        try {
            let response: any;

            response = await axios.get<KycData[]>(`${process.env.REACT_APP_API_URL}/api/User/GetKycInformation`, {
                params: { clientId: clientId }
            });

            return response.data;
        } catch (error) {
            console.log('Error fetching kyc history:', error);
            throw new Error('Failed to fetch kyc history');
        }
    }
}