import { XIcon } from "lucide-react";
import DashboardLogo from "../assets/voltox-dashboard-logo.png";
import type { RouteId } from "../config/routes";
import { routes as routesConfig } from "../config/routes";
import { cn } from "../lib/utils";
import { Button } from "./ui/button";
import { Card, CardContent, CardHeader } from "./ui/card";

interface Route {
    id: RouteId;
    title: string;
    icon: React.ElementType;
    component: () => JSX.Element;
}

interface SidebarProps {
    routes?: typeof routesConfig;
    activeItem: RouteId;
    onItemClick: (id: RouteId) => void;
    className?: string
    toggleSidebar: () => void
}

export const Sidebar = ({
    routes = routesConfig,
    activeItem,
    onItemClick,
    className,
    toggleSidebar
}: SidebarProps) => {
    const mainRoutes = routes.filter(route => route.id !== 'settings');
    const settingsRoute = routes.find(route => route.id === 'settings');

    return (
        <Card style={{ height: "100svh", zIndex: 2 }} className={cn(
            "responsiveSideBar position-relative rounded-none border-r border-gray-200 d-flex flex-column justify-content-around align-items-center",
            className
        )}>
            <div style={{ height: "70svh" }} className="d-flex flex-column justify-content-around align-items-center">
                <XIcon onClick={toggleSidebar} />
                <CardHeader className="">
                    <img src={DashboardLogo} className="w-100" id="sidebarLogo" alt="logo" />
                </CardHeader>

                <CardContent className="">
                    <nav className="space-y-2 d-flex flex-column">
                        {mainRoutes.map((route: Route) => {
                            const Icon = route.icon;
                            return (
                                <Button
                                    key={route.id}
                                    variant={activeItem === route.id ? "secondary" : "ghost"}
                                    className={`justify-start h-[70px] font-normal text-sm ${activeItem === route.id ? "rounded-xl" : "rounded-none transition-all duration-200"
                                        } ${route.id ?? ''}`}
                                    onClick={() => onItemClick(route.id)}
                                >
                                    <Icon className="mr-2 h-4 w-4" />
                                    <div className="itemName">{route.title}</div>
                                </Button>
                            );
                        })}
                    </nav>
                </CardContent>

                {settingsRoute && (
                    <div>
                        <Button
                            id="settings"
                            variant={activeItem === 'settings' ? "secondary" : "ghost"}
                            className={`justify-start border-t h-[70px] font-normal text-sm ${activeItem === 'settings' ? "rounded-xl" : "rounded-none transition-all duration-200"
                                }`}
                            onClick={() => onItemClick('settings')}
                        >
                            <settingsRoute.icon />
                            {settingsRoute.title}
                        </Button>
                    </div>
                )}
            </div>
        </Card>
    );
}; 