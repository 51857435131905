import React from "react";

interface CheckIconProps {
    className?: string;
    stroke?: string;
    strokeWidth?: string;
}

const CheckIcon: React.FC<CheckIconProps> = ({ className, stroke, strokeWidth }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="86"
            height="86"
            fill="#34dc94"
            className={`bi bi-check ${className || ""}`} // Include the className prop if provided
            viewBox="0 0 16 16"
            stroke={stroke ?? ""}
            stroke-width={strokeWidth ?? ""}
        >
            <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z" />
        </svg>
    );
};

export default CheckIcon;