import { useState } from 'react';
import { Sidebar } from '../components/Sidebar';
import { UserProfileDropdown } from '../components/UserProfileDropdown';
import { RouteId, routes } from '../config/routes';
import { useUser } from "../contexts/UserContext";
import { Admin } from "./Admin";

export const Dashboard = () => {
    const { user } = useUser();
    const [activeSection, setActiveSection] = useState<RouteId>('dashboard');

    const ActiveComponent = routes.find(route => route.id === activeSection)?.component;
    const [isVisible, setIsVisible] = useState<boolean>(() => {
        return sessionStorage.getItem("sidebar") ? sessionStorage.getItem("sidebar") === "true" : true;
    });

    const toggleSidebar = () => {
        setIsVisible(!isVisible);
        sessionStorage.setItem("sidebar", (!isVisible).toString());
    }

    if (user?.role == "Admin") {
        return (
            <main>
                {<Admin />}
            </main>
        );
    }
    else {
        return (
            <div className="bg-[#F5F6FA] row flex-nowrap w-100 g-0" style={{ height: "100svh" }}>
                {!isVisible &&
                    <div style={{ width: "10px", position: "absolute", top: "50%", left: "0", transform: "translateY(-50%)" }} onClick={toggleSidebar}>
                        {/* <CaretRight width={'42'} height={'42'} stroke='#34dc94' strokeWidth='2' /> */}
                        <div id="openSidebar">
                            <span>Open Sidebar</span>
                        </div>
                    </div>
                }
                {<div className={isVisible ? "sidebar col-lg-2-half col-3" : "sidebar hiddenSidebar"}>
                    <Sidebar
                        routes={routes}
                        activeItem={activeSection}
                        onItemClick={setActiveSection}
                        toggleSidebar={toggleSidebar}
                    />
                </div>
                }
                <div className={!isVisible ? "col-12" : "col-md-9 col-lg-9-half col-9"}>
                    <nav className="bg-white">
                        <div className="flex h-16 items-center pr-4 pl-12">
                            {/* <div className="flex items-center ml-64 relative">
                                <Input
                                    type="text"
                                    placeholder="Search"
                                    className="bg-[#F5F6FA] rounded-full w-96 pl-11 m-0"
                                />
                                <SearchIcon className="w-4 h-4 absolute left-4 top-3" />
                            </div> */}
                            <div className="ml-auto flex items-center space-x-4">
                                <UserProfileDropdown />
                            </div>
                        </div>
                    </nav>

                    <main className="flex-1 m-md-8 pb-0" id='main-dashboard'>
                        {ActiveComponent && <ActiveComponent />}
                    </main>
                </div>
            </div>
        );
    }
}; 