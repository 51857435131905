import { createContext, useContext, useEffect, useState } from 'react';
import { authService } from '../services/api.service';
import { tokenService } from '../services/token.service';
import { userService } from '../services/user.service';
import { SimpleDecodedToken } from '../types/auth.types';

interface UserContextType {
    user: SimpleDecodedToken | null;
    setUser: (user: SimpleDecodedToken | null) => void;
    refreshToken: () => Promise<void>;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export function UserProvider({ children }: { children: React.ReactNode }) {
    const [user, setUser] = useState<SimpleDecodedToken | null>(() => {
        // Try to get user data from localStorage on initial load
        const token = localStorage.getItem("token");
        if (token && token != "null") {
            const decodedToken = userService.decodeToken(token);
            const simplifiedToken = userService.simplifyDecodedToken(decodedToken);
            return simplifiedToken;
        }
        else {
            return null;
        }
    });

    const refreshToken = async () => {
        if (user?.expirationTime) {
            let time = user?.expirationTime * 1000;

            if (time < Date.now()) {
                console.log("Expired, refreshing now...");
                let refreshToken = localStorage.getItem("refreshToken");

                if (refreshToken && refreshToken != "null") {
                    await authService.refreshToken(refreshToken).then((response: any) => {
                        tokenService.setTokens({
                            token: response.data.token,
                            refreshToken: response.data.refreshToken
                        });

                        const decodedToken = userService.decodeToken(response.data.token);
                        const simplifiedToken = userService.simplifyDecodedToken(decodedToken);
                        setUser(simplifiedToken);
                    });
                }
            }
        }
    }

    useEffect(() => {
        //verify if you have FR subscription
        const frSubscriptionSimplified = user?.frSubscription ? JSON.parse(user?.frSubscription) : null;
        if (frSubscriptionSimplified && frSubscriptionSimplified.IsActive != null) {
            if (frSubscriptionSimplified.IsActive.toLowerCase() == "false") {
                console.log("Face Recognition Subscription expired!");
            }
        }

        //verify if you have AGE Subscription
        const ageSubscriptionSimplified = user?.ageSubscription ? JSON.parse(user?.ageSubscription) : null;
        if (ageSubscriptionSimplified && ageSubscriptionSimplified.IsActive != null) {
            if (ageSubscriptionSimplified.IsActive.toLowerCase() == "false") {
                console.log("Age Verification Subscription expired!");
            }
        }

        // When user changes, save to localStorage if it exists, remove if null
        if (user) {
            localStorage.setItem('user', JSON.stringify(user));
        }
        else {
            // Check if we have a token but no user data
            const currentUser = userService.getCurrentUser();
            if (currentUser) {
                setUser(currentUser);
            } else {
                localStorage.removeItem('token');
                localStorage.removeItem('refreshToken');
                localStorage.removeItem('user');
                localStorage.removeItem('reqId');
                sessionStorage.removeItem('faceFrameBlob');
                sessionStorage.removeItem('sidebar');
            }
        }
    }, []);

    return (
        <UserContext.Provider value={{ user, setUser, refreshToken }}>
            {children}
        </UserContext.Provider>
    );
}

export function useUser() {
    const context = useContext(UserContext);
    if (context === undefined) {
        throw new Error('useUser must be used within a UserProvider');
    }
    return context;
}